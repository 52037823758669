import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const carwashRequireLogin = (to, from, next) => {
  if (store.state.isLogin) {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home.vue"),
    children: [
      {
        path: "",
        name: "index",
        component: () => import("@/views/newIndex.vue"),
      },
      {
        path: "create",
        name: "addPlate",
        component: () => import("@/components/login/addPlate.vue"),
      },
      {
        path: "",
        name: "loginTab",
        component: () => import("@/views/login.vue"),
        children: [
          {
            path: "login",
            name: "login",
            component: () => import("@/components/login/login.vue"),
          },
          {
            path: "register",
            name: "register",
            component: () => import("@/components/login/register.vue"),
          },
          {
            path: "verify",
            name: "enterVerify",
            component: () => import("@/components/login/enterVerify.vue"),
          },
          {
            path: "mobile",
            name: "mobile",
            component: () => import("@/components/login/mobile.vue"),
          },
          {
            path: "password",
            name: "resetPassword",
            component: () => import("@/components/login/password.vue"),
          },
        ],
      },
      {
        path: "",
        name: "carwash",
        component: () => import("@/views/carwash.vue"),
        // beforeEnter:carwashRequireLogin,
        children: [
          {
            path: "carwash/setting",
            name: "setting",
            component: () => import("@/components/carwash/carwash_setting.vue"),
            // beforeEnter:carwashRequireLogin
          },
          {
            path: "carwash/subscription",
            name: "subscription",
            component: () =>
              import("@/components/carwash/carwash_subscription.vue"),
            // beforeEnter:carwashRequireLogin
          },
          {
            path: "carwash/tickets",
            name: "tickets",
            component: () => import("@/components/carwash/carwash_voucher.vue"),
            // beforeEnter:carwashRequireLogin
          },
          {
            path: "carwash/features",
            name: "features",
            component: () =>
              import("@/components/carwash/carwash_features.vue"),
            // beforeEnter:carwashRequireLogin
          },
          {
            path: "carwash/findCarwash",
            name: "findCarwash",
            component: () =>
              import("@/components/carwash/carwash_findCarwash.vue"),
            // beforeEnter:carwashRequireLogin
          },
          {
            path: "carwash/events",
            name: "carwashEventList",
            component: () => import("@/components/account/payment_event.vue"),
          },
          {
            path: "carwash/events/:id",
            name: "carwashEventDetails",
            component: () =>
              import("@/components/account/payment_event_item_details.vue"),
          },
          {
            path: "carwash/result",
            name: "carwashTicketResult",
            component: () => import("@/components/carwash/carwash_ticket_result.vue"),
          },
          {
            path: "carwash/subscription/result",
            name: "carwashSubscriptionResult",
            component: () => import("@/components/carwash/carwash_subscription_result.vue"),
          },
        ],
      },
      {
        path: "account",
        name: "account",
        component: () => import("@/views/account.vue"),
        children: [
          {
            path: "notice",
            name: "notice",
            component: () => import("@/components/account/notice.vue"),
          },
          {
            path: "notice/details/:id",
            name: "noticeDetails",
            component: () =>
              import("@/components/account/payment_event_item_details.vue"),
          },
          {
            path: "payment",
            name: "payment",
            component: () => import("@/components/account/payment.vue"),
          },
          {
            path: "qrcode",
            name: "qrCode",
            component: () => import("@/components/account/payment_qrcode.vue"),
          },
          {
            path: "tickets",
            name: "tickets",
            component: () => import("@/components/account/payment_tickets.vue"),
          },
          {
            path: "tickets/:id",
            name: "ticketDetails",
            component: () =>
              import("@/components/account/payment_ticket_details.vue"),
          },
          {
            path: "coupons",
            name: "coupons",
            component: () => import("@/components/account/payment_coupons.vue"),
          },
          {
            path: "rewards",
            name: "rewards",
            component: () => import("@/components/account/payment_rewards.vue"),
          },
          {
            path: "rewards/gift",
            name: "rewardsGift",
            component: () =>
              import("@/components/account/payment_rewards_gift.vue"),
          },
          {
            path: "rewards/list",
            name: "rewardList",
            component: () =>
              import("@/components/account/payment_rewards_record.vue"),
          },
          {
            path: "events",
            name: "eventList",
            component: () => import("@/components/account/payment_event.vue"),
          },
          {
            path: "events/:id",
            name: "paymentEventDetails",
            component: () =>
              import("@/components/account/payment_event_item_details.vue"),
          },
          {
            path: "service",
            name: "service",
              component: () => import("@/components/account/service.vue"),
          },
          {
            path: "service/:type",
            name: "service",
              component: () => import("@/components/account/service.vue"),
          },
          {
            path: "service/carwash/:id",
            name: "accountServiceCarwash",
            component: () => import("@/components/account/service_detail_carwash.vue"),
          },
          {
            path: "service/order/:type/:id",
            name: "accountServiceOrder",
            component: () => import("@/components/account/service_detail_order.vue"),
          },
          {
            path: "service/repair/:id",
            name: "accountRepair",
            component: () => import("@/components/account/service_detail_repair.vue"),
          },
          {
            path: "info",
            name: "info",
            component: () => import("@/components/account/info.vue"),
          },
          {
            path: "transfer/record",
            name: "transferRecord",
            component: () =>
              import("@/components/account/payment_transfer_record.vue"),
          },
          {
            path: "termService",
            name: "termService",
            component: () => import("@/components/account/termService.vue"),
          },
          {
            path: "info/edit",
            name: "edit",
            component: () => import("@/components/account/edit_info.vue"),
            children: [
              {
                path: "name",
                name: "editName",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "email",
                name: "editEmail",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "birthday",
                name: "editBirthday",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "gender",
                name: "editGender",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "myVehicle",
                name: "editVehicle",
                component: () =>
                  import("@/components/account/edit_infoDetail.vue"),
              },
              {
                path: "add",
                name: "addPlate",
                component: () =>
                  import("@/components/account/edit_addPlate.vue"),
              },
            ],
          },
          {
            path: "password",
            name: "password",
            component: () => import("@/components/account/password.vue"),
          },
          {
            path: "password/edit",
            name: "editPassword",
            component: () => import("@/components/account/edit_password.vue"),
            children: [
              {
                path: "",
                name: "enterOld",
                component: () =>
                  import("@/components/account/edit_passwordOld.vue"),
              },
              {
                path: "new",
                name: "enterNew",
                component: () =>
                  import("@/components/account/edit_passwordNew.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/events/:id",
    name: "externalEvent",
    component: () => import("@/views/external.vue"),
  },
  {
    path: "/wash",
    name: "wash",
    component: () => import("@/views/caroWash.vue"),
  },
  {
    path: "/nonmembers",
    name: "nonmembers",
    component: () => import("@/views/nonmembers.vue"),
  },
  {
    //重定向 新芽舊最新消息
    path: "news/:id",
    redirect: "carwash/events/:id",
  },
  {
    //重定向 新芽舊活動
    path: "event/:id",
    redirect: "events/:id",
  },
  {
    //重定向 新芽舊註冊活動
    path: "event/:id/info",
    redirect: "events/:id",
  },
  {
    //重定向 新芽舊QR Code註冊活動
    path: "demologin",
    redirect: (to) => {
      const eventId = to.query.event_id;
      return `events/qrcode`;
    },
  },
  {
    //重定向 藍新金流付款成功或失敗
    path: "app/carwash/checkout/result",
    redirect: (to) => {
      return `carwash/result`;
    },
  },
  {
    //重定向 新芽舊洗車
    path: "carwash",
    redirect: "carwash/features",
  },
  {
    //重定向 新芽舊套票
    path: "ticket",
    redirect: "carwash/tickets",
  },
  {
    //重定向 新芽舊訂閱
    path: "carwashsubscribe",
    redirect: "carwash/subscription",
  },
  { //404導至首頁
    path: "*",
    // name: "error",
    // component: () => import("@/views/NotFoundPage.vue"),
    redirect: "/",
  },
  {
    path: "/lineActLink/:id?",
    name: "lineActLink",
    component: () => import("@/views/lineActLink.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { x: 0, y: 0 }
    }
  } 
});
// 在 router 中設定 global 屬性
router.global = { isApp: null };

export default router;
