<template>
    <v-dialog v-model="dialog" fullscreen>
        <dialogToolbar :toolBar="toolBar"/>
        <v-row no-gutters class="bg-white fill-width justify-center">
            <div class="bg-white d-flex flex-column" style="max-Width:640px;">
                <div class="d-none d-sm-flex ma-4 text-20">隱私權聲明</div>
                <div class="ma-4 t-color-n1">
                    本隱私權聲明適用於香港商運通汽車有限公司台灣分公司 - 統編 70807598(以下稱「本公司」) 於 https:// carguru.com 網站域名及次級網域名(以下稱「本網站」)所提供的服務。
                    當您使用本網站時，即表示您明示同意受本隱私權聲明條款及條件所拘束， 若您不同意，請不要使用或取得本網站所提供之服務。如下所述，您的個人資料將可能會被揭露予第三人，且受本公司及第三人使用。
                    本隱私權聲明並為服務條款之一部分。
                </div>
                <div class="ma-4 t-color-n1">
                    【蒐集之資訊】
                </div>
                <div class="ma-4 t-color-n1">
                    本公司蒐集您個人資料之主要目的，係為您提供本網站順利的、 有效率的及特定選項之服務，以利本公司提供最符合您需求之服務及功能， 且規劃訂製本公司之服務而使您使用本網站所提供之服務得以更順利。
                    <br />
                    本網站對使用者之個人資料蒐集、處理及利用，將嚴格遵守中華民國『個人資料保護法』之規範。 如您一旦提供個人資料予本公司，對本公司而言，您即非匿名人士。如您選擇使用本公司之服務，
                    本公司可能要求您提供聯絡及辨認資料及本網站各類表格所要求提供之其他個人資料。 本公司將依據您於本網站上之行為自動蒐集資料，作為內部研究，以便更瞭解本網站之使用者。 本網站所蒐集之資料包括但不限於您來自哪個
                    URL (不論此 URL 是否在本網站上)， 您接下來前往之 URL (不論此 URL 是否在本網站上)，目前使用何種瀏覽器，以及您的 IP 地址等。
                    <br />
                    本公司於部份網頁將使用類似 cookies 之資料蒐集裝置來幫助分析網頁之流動狀況、 衡量促銷活動之效果，並增進信任度及安全性。若您的瀏覽器允許，
                    您永遠有權拒絕本網站之cookies，雖然於該等情況下，您可能無法使用本網站某些特定功能， 並於某一階段內，必須更頻繁地重新輸入密碼。 此外，您可能將面臨由第三人於本網站上某些網頁中存放之cookies
                    或其他類似裝置， 本公司並不控制第三人對 cookies 之使用。
                    <br />
                    若您選擇透過本網站購買、出售物品、建立您所持有車輛之資料或有其他交易行為， 本公司將蒐集您購買、出售物品、持有之車輛及其他交易行為資料， 同時於本網站上之信用評價區，亦將蒐集其他使用者對您的意見。
                    <br />
                    有關本公司向您蒐集或所蒐集到與您有關之資料及誰有權取得該等資料，請詳閱下列敘述：
                </div>
                <div class="ma-4 t-color-n1">
                    【個人資料蒐集、處理及利用告知暨同意】
                </div>
                <div class="mx-5 my-4 t-color-n1">
                    1. 本公司/本網站根據所提供下列特定目的之服務，將蒐集、處理及利用個人資料：
                </div>
                <div class="mx-9 my-0 t-color-n1">
                    (○一三)公共關係
                    <br />
                    (○四○)行銷（包含本公司/本網站跨站台共同行銷業務）
                    <br />
                    (○六三)非公務機關依法定義務所進行個人資料之蒐集、處理及利用
                    <br />
                    (○六九)契約、類似契約關係或其他法律關係事務
                    <br />
                    (○九○)消費者、客戶管理與服務
                    <br />
                    (○九一)消費者保護事務
                    <br />
                    (○九八)商業與技術資訊
                    <br />
                    (一○七)採購與供應管理
                    <br />
                    (一一八)智慧財產權、光碟管理及其他相關行政
                    <br />
                    (一二九)會計與相關服務
                    <br />
                    (一三五)資(通)訊服務
                    <br />
                    (一三六)資（通）訊與資料庫管理
                    <br />
                    (一三七)資通安全與管理
                    <br />
                    (一四八)網路購物及其他電子商務服務
                    <br />
                    (一五○)輔助性與後勤支援管理
                    <br />
                    (一五二)廣告或商業行為管理
                    <br />
                    (一五七)調查、統計與研究分析
                    <br />
                    (一八一)其他經營合於營業登記項目或組織章程所定之業務
                    <br />
                    (一八二)其他諮詢與顧問服務
                </div>

                <div class="mx-5 my-4 t-color-n1">
                    2. 本公司/本網站蒐集、處理及利用之資料類別除依上開特定目的相關者外， 您個人資料利用之期間、地區、對象及方式如下：
                </div>
                <div class="mx-9 my-0 t-color-n1">
                    期間：依上開特定目的存在之期間，本公司/本網站考量本次蒐集之個人資料， 不再使用或特定目的消失後，將定期刪除。
                    <br />
                    地區：本公司及經本公司授權之人員或為達成交易服務之上下游相關機關因執行上開特定目的所在地。
                    <br />
                    對象：經本公司授權之人員或為達成交易服務及瀏覽本網站平台網頁之上下游相關機關。
                    <br />
                    方式：依上開特定目的範圍內依合理及合法方式為之，本公司/本網站蒐集、 處理及利用您之個人資料將以紙本、電腦系統、電子檔案、電話、email、
                    傳真、訊息推播、郵寄等方式，或符合個人資料保護相關法令以自動化機器或其他非自動化之利用方式使用。
                </div>
                <div class="mx-5 my-4 t-color-n1">
                    3. 蒐集之個人資料類別：
                </div>
                <div class="mx-9 my-0 t-color-n1">
                    識別類（C001 辨識個人者、C002 辨識財務者、C003 政府資料中之辨識者）等，具體事項如姓名、行照、牌照登記證、身分證統一編號、性別、 出生年月日、通訊方式及其他詳如相關申請書或契約書之內容。
                </div>
                <div class="mx-5 my-4 t-color-n1">
                    4. 您可就個人資料保護法(以下簡稱「個資法」)行使下列權利：
                </div>
                <div class="mx-9 my-0 t-color-n1">
                    除有個資法第十條所規定之例外情形外，得向本公司/本網站查詢、 請求閱覽或請求製給複製本，惟本公司/本網站依個資法第十四條之規定得酌收必要成本費用。
                    <br />
                    得向本公司/本網站請求補充或更正，惟依個資法施行細則第十九條規定， 您應適當釋明其原因及事實。
                    <br />
                    本公司/本網站如有違反個資法規定蒐集、處理或利用您之個人資料， 依個資法第十一條第四項規定，您得向本公司/本網站請求停止蒐集。
                    <br />
                    依個資法第十一條第二項規定，個人資料正確性有爭議者， 得向本公司/本網站請求停止處理或利用您之個人資料。 惟依該項但書規定，本公司因執行業務所必須並註明其爭議或經您書面同意者， 不在此限。
                    <br />
                    依個資法第十一條第三項規定，個人資料蒐集之特定目的消失或期限屆滿時， 得向本公司/本網站請求刪除、停止處理或利用您之個人資料。 惟依該項但書規定，本公司因執行業務所必須或經您書面同意者，不在此限。
                </div>
                <div class="mx-5 my-4 t-color-n1">
                    5. 您欲行使上述權利時，可隨時向本公司/本網站受理窗口提出申請， 受理申請之電子郵件信箱為 service@carguru.com.tw。
                </div>
                <div class="mx-5 my-4 t-color-n1">
                    6. 您不提供個人資料所致權益之影響：
                </div>
                <div class="mx-9 my-0 t-color-n1">
                    除法律規定外，您得自由選擇是否提供相關個人資料及類別， 惟您所拒絕提供之個人資料及類別，如係辦理業務審核或作業所需之資料，
                    本公司/本網站恐將無法進行必要之業務審核或作業而無法提供您相關服務或無法提供較佳之服務。
                </div>
                <div class="mx-5 my-4 t-color-n1">
                    7. 經本公司/本網站告知上開事項，當事人或受告知人已清楚了解上開告知事項， 並同意依據告知事項所述，提供個人資料予本公司/本網站。
                </div>

                <div class="ma-4 t-color-n1">
                    【本公司對您個人資料之使用】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本公司使用您的個人資料以幫助本公司提供您所要求之服務， 本公司使用您所建立個人資料檔案中之資料及本公司自您現在或過去於本網站上之行為所取得之其他資料， 以解決爭議、檢修障礙問題及執行本公司之服務條款。
                    偶而，本公司可能檢視多個使用者以確認問題所在或解決爭議， 特別是本公司可能對您的資料進行檢查以識別那些使用多個會員帳號或化名之使用者。
                    <br />
                    您同意本公司使用您的個人資料來加強市場行銷及促銷、統計分析網站利用方式、 加強本公司所提供之服務內容及產品及進行潛在需求媒合， 並依據使用者之需求規劃訂製本網站之內容、網頁設計及服務，
                    以利本公司改善並調整本網站使其更符合您的需求。
                    <br />
                    您同意本公司得使用您的資料與您聯絡， 並以任何方式傳送本公司相關企業之各項行銷或活動訊息給您， 例如特定標題廣告、行政通知、產品內容、潛在需求媒合及有關您使用本網站之訊息。
                    透過接受服務條款及隱私權聲明，您明示同意收取此項訊息。
                    <br />
                    若您不同意接受前項各行銷或活動訊息之傳送及利用，您可隨時向本公司/本網站受理窗口提出申請， 受理申請之電子郵件信箱為service@carguru.com.tw，待處理作業完成後，
                    本網站即不再對您為任何行銷或活動訊息之傳送及利用。
                </div>

                <div class="ma-4 t-color-n1">
                    【個人資料之揭露】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    緣於現存之管制環境， 本公司無法確保所有您私人通訊及其他個人資料不會被以本隱私權聲明所述以外之其他方式洩露。 例如(但不限以下所述)，於某些法令規定下，本公司可能會提供資料予政府或第三人，
                    或第三人可能違法攔截、存取或傳輸私人通訊。因此，本公司雖遵守法規、法令及相關標準， 並採取適當之安全控制措施來保護您的隱私權，本公司並未承諾， 且請您了解您的個人資料或私人通訊仍有傳送至外部之可能或風險。
                    <br />
                    政策上，本公司絕不會將與您有關之個人資料出租、出售、任意洩漏或提供予第三人， 然而，下列所述，為部分可能揭露您個人資料之情形。
                </div>

                <div class="ma-4 t-color-n1">
                    【刊登廣告者】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本公司蒐集(自所有使用者帳戶蒐集資料)個人資料，且為市場行銷、 宣傳廣告或潛在需求媒合之目的，以非可識別個人之方式揭露該資料予刊登廣告者及其他第三人，
                    惟本公司將不會向此等企業體揭露任何可能被用來辨識您身分之個人資料。 一些特定資料，例如姓名、電子郵件地址、密碼、信用卡號碼及銀行帳號，
                    將永遠不會被揭露予市場行銷廣告刊登者。若您於本網站上之廣告點按滑鼠右鍵並選擇內容， 您將可看到該廣告提供者之URL。
                </div>

                <div class="ma-4 t-color-n1">
                    【委外廠商或服務提供者】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本公司可能使用委外廠商或服務提供者以加強本公司之服務(例如：搜尋、留言板、問卷調查、履行車輛買賣契約、售後服務…等)， 故本公司可能直接提供您某些個人資料予該等委外廠商或服務提供者。某些情形下，
                    委外廠商或服務提供者將直接向您蒐集資料(例如於本公司要求委外廠商或服務提供者為本公司進行市場調查之情形)。 惟此等委外廠商或服務提供者使用本公司所提供之資料，均受到保密合約所限制，
                    若您直接提供額外資料予委外廠商或服務提供者， 則就此額外部份應受該等廠商或服務提供者自身之相關隱私權聲明所規範。
                </div>

                <div class="ma-4 t-color-n1">
                    【其他公司組織】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本網站、本公司及其子公司、合資企業或關係企業均可能與其他企業進行組織合併或被收購，一旦發生， 您可預見本網站會將您全部資料轉交他人以便繼續提供服務，
                    您將收到此類事件之通知，並且本公司將通知新結合之組織遵循本隱私權聲明之規定。
                </div>

                <div class="ma-4 t-color-n1">
                    【合法要求】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本網站有可能與執法機關及其他第三人合作執行法令規定，例如，智慧財產權侵害、 詐欺及其他權利保護。本公司可依法令規定且您已授權本公司得自行判斷認為有必要或適當者，
                    可向執法機關或其他政府官員提供與詐欺、智慧財產權侵害、其他不法行為、 或將使本公司或您負擔法律責任之行為，調查有關之任何您的資料。
                    進而，本公司得提供與詐欺、智慧財產權侵害、盜版或其他不法行為之調查有關之會員帳號、 姓名、地址、郵遞區號、國家、電話號碼、電子郵件及公司名稱，以維持信任及本網站社群之安全，
                    並執行本公司之服務條款、隱私權聲明及任何已張貼應適用於您使用本網站內所有服務之政策或規則。
                </div>

                <div class="ma-4 t-color-n1">
                    【您對其他使用者資料之使用】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    為促進使用者間之交易活絡及加強所有本網站使用者間之互動，依本網站服務條款之規定， 本網站之服務允許您有限度地存取其他使用者之聯絡資料，站在使用者之立場，
                    您可以取得交易相對人之會員編號、電子郵件位址及其他資料， 而交易相對人則可取得賣方之會員編號、電子郵件位址及其他聯絡資料。
                    <br />
                    除本公司及該其他使用者於經過充分說明後表示同意外，依本網站服務條款、 本隱私權聲明及其他本網站現今或嗣後之規則或聲明之規定，在任何情況之下， 您皆不得揭露其他使用者之個人資料予第三人。請注意，執法人員、
                    已認證之權利所有者方案之參與人及其他權利持有人被賦予之資料存取許可權並不相同。
                </div>

                <div class="ma-4 t-color-n1">
                    【密碼控制】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    您應就您的會員帳號及密碼所發生之所有行為負責，包括但不限於費用或因此產生之損失。 您應妥善保管您於本網站之會員帳號及密碼，且不應揭露予任何第三人。
                    如果您選擇與向您提供額外服務之第三者分享您的會員帳號及密碼或您的資料， 您應就您的會員帳號及密碼所發生或導致之所有行為負責，因此您應審閱第三者之隱私權聲明。
                    如果您對您的密碼失去控制，您可能對您個人資料失去實質控制， 而且可能受到以您名義成立之行為所拘束。因此，如果您的密碼因任何原因受到危害， 您必須立刻依照本網站客服人員指引或登入網頁之說明來變更您的密碼。
                </div>

                <div class="ma-4 t-color-n1">
                    【審閱及變更您個人登入註冊資料】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本公司提供給您可以自行登入網站並且輸入新資料之方式，自動審閱及變更您提供予本公司資料之能力。 一般而言，本公司不會依您的要求而修改您的資料，因驗證您的帳號是非常困難的。
                    經由本網站中的首頁→登入→會員中心→個人資料/密碼，您可以改變您的資訊及密碼， 如果您的資料有異動，您必須即刻加以更新。
                    <br />
                    一旦張貼，您不得自行更正或移除於本網站上或其他訊息區或信用評價區上之任何公開貼文。 某些情況下，本網站將會於本公司之檔案中保留您曾經請求移除之訊息，例如為解決爭議、
                    檢修障礙問題及執行本網站之服務條款。基於技術及法律之限制，包括存取備份系統， 該筆資料將不會從自本網站之資料庫中被完全移除。 因此，您不應期待所有您的個人資料會如您所要求地自本公司之資料庫中被完全移除。
                </div>

                <div class="ma-4 t-color-n1">
                    【其他資料蒐集者】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    這份聲明僅規範本公司向您蒐集、處理及利用之個人資料。於您揭露您的資料予其他人之情形， 無論其為出價者、買方或賣方，其使用及揭露您所揭露予他們資料之行為，
                    應尤其自身負相對之法律責任，概與本公司無涉。於本公司使用第三人刊登廣告者之情形， 應由其遵守其自身之隱私權聲明。因本網站無法控制及修改第三人之隱私權聲明，
                    您應該受到該第三人自身之隱私權聲明之拘束，於您揭露個人資料予其他人之前， 本公司鼓勵您向對方提出問題。
                </div>

                <div class="ma-4 t-color-n1">
                    【安全性】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本網站遵守法令規定以保護您個人資料之秘密性。
                    <br />
                    本網站將個人資料視為必須保護其免於滅失及免於未經授權而存取之資產， 本公司將使用多項安全措施以保護此類資料免於本網站內外部使用者未經授權之存取。
                </div>

                <div class="ma-4 t-color-n1">
                    【條款衝突】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    請注意，本隱私權聲明係本公司對您隱私權保護之唯一規範。 如因版本或內容不一致，導致本隱私權聲明之條款與條件與其他可能於本網站中出現之隱私權陳述發生衝突時， 您同意優先適用本隱私權聲明中之條款及條件。
                </div>

                <div class="ma-4 t-color-n1">
                    【通知】
                </div>
                <div class="mx-4 my-0 p3 n1-text">
                    本公司有權以於本網站上張貼方式而隨時修改本隱私權聲明， 在不超過上述蒐集之特定目的前提下，所有修改條款，應自初次張貼於網站上起7天後自動生效。
                </div>

            </div>
            <br />
            <br />
        </v-row>
    </v-dialog>
</template>

<script>
import dialogToolbar from "@/components/dialogToolbar.vue";
import { mapState, mapMutations } from "vuex";
export default {
    components: { dialogToolbar },
    watch: {
        "$route.hash": {
            handler: function (dialog_id) {
                const vm = this;
                if (dialog_id === '#privacy-dialog') {
                    vm.dialog = true;
                } else {
                    vm.dialog = false;
                }
            }, 
            immediate: true
        },
    },
    computed: {
        ...mapState(["isApp"]),
        ...mapMutations(["setIsApp"]),
        isApp() {
            return this.$store.state.isApp
        },
    },
    data: () => ({
        toolBar: {
            title: "",
            subTitle: ""
        },
        dialog: false,
        width: "",
        fullscreen: false
    }),
    created() { },
    mounted() {

    },
    methods: {

    }
}
</script>
