import Vue from 'vue';
import Vuex from 'vuex';
import { setToken, getToken } from "@/utils/util";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ["isLogin", "user", "thirdParty", "my", "ticket", "car"],
    }),
  ],
  state: {
    isLogin: false,
    token: getToken(),
    user: {},
    thirdParty: {},
    my: {},
    ticket: {},
    car: [],
  },
  getters: {
    getLogin: function (state) {
      return state.isLogin;
    },
  },
  mutations: { 
    setIsLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    // 設定Token
    setToken(state, token) {
      state.token = token;
      setToken(token);
    },
    setUser(state, user) {
      state.user = user;
    },
    setThirdParty(state, thirdParty) {
      state.thirdParty = thirdParty;
    },
    setMy(state, my) {
      state.my = my;
    },
    setTicket(state, ticket) {
      state.ticket = ticket;
    },
    setCar(state, car) {
      state.car = car;
    },
  },
  actions: {
    updateMy(context, status) {
      context.commit("setMy", status);
    },
  },
  modules: {
  }
})
