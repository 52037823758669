import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#0055B8',
            secondary: '#748094',
            ltPrimary: '#c9e2ff',
            accent: '#2ab58b',
            alert: '#f36161',
            disabled: '#eee',
            normal: "#eee"
          },
        },
      },
      icons: {
        iconfont: 'fa',
      },
});
