<template>
    <div class="main">
        <div><img src="@/assets/update/logo.svg"></div>
        <div><img src="@/assets/update/landing.svg" class="image"></div>
        <div class="t-color-n1 font-weight-bold text-20">系統全面升級中<br />AM 7:00恢復服務</div>
    </div>
</template>
  
<script>
export default {
    name: 'updateView',
    components: {},
    data() { },
    computed: {},
    created() { },
    mounted() { },
    methods: {}
}
</script>
<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-y: hidden;
}

.main {
    display: block;
    background-color: #FFF;
    text-align: center;
    height: 100vh;
    padding: 0px;
    margin: 0px;
    border: 0px;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto 40px auto;
    width: 20%;
}

@media screen and (max-width: 767px) {
    .main {
        display: block;
        align-items: center;
    }

    .image {
        height: auto;
        width: 70%;
    }
}
</style>
  