<template>
    <v-toolbar class="elevation-0">
        <v-icon aria-hidden="true" class="t-color-white text-32">mdi-chevron-left</v-icon>
        <div class="layout column align-center text-16  t-color-primary">{{ toolBar.title }} {{
            toolBar.subTitle }}</div>
        <v-icon aria-hidden="true" class="t-color-n1 text-32" @click="$router.back()">mdi-close</v-icon>
    </v-toolbar>
</template>
<script>
export default {
    props: {
        toolBar: { type: Object, required: true },
    },
    data() {
        return {
        };
    },
    mounted() {
        const vm = this;
    },
    methods: {

    },
};
</script>
<style scoped></style>