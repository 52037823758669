import Cookies from "js-cookie";
// cookie保存的天数
const config = {
  cookieExpires: 1 /* token在Cookie中儲存天數 */,
  tokenName: "carguru_tk" /* token name */,
}
const TOKEN_KEY = config.tokenName;

export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: config.cookieExpires });
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token != null) {
    return token;
  } else {
    const token = localStorage.getItem("carguru_tk");
    return token;
  }
};

export const removeToken = () => {
  Cookies.remove(TOKEN_KEY);
};

export const vertifyTaxId = (idStr) => {
  if (idStr == null || idStr == undefined) return false;

  const regex = /^\d{8}$/;

  if (!idStr.match(regex)) {
    console.log("regex not match");
    return false;
  }

  const len = 8;
  var idArray = [];
  idStr.split("").forEach((id) => idArray.push(id - "0"));
  const weight = [1, 2, 1, 2, 1, 2, 4, 1];

  var sum = 0;
  for (var i = 0; i < len; i++) {
    // conver char to int
    //idArray[i] = idArray[i] - '0' ;
    var p = idArray[i] * weight[i];
    //console.log(idArray[i], weight[i], p);
    var s = Math.floor(p / 10) + (p % 10);
    sum += s;
  }
  //console.log('sum', sum);
  // when s[6]==7, after adding the two, its value will be equal to 10, in this case, 1 or 0 should be used to calculate the sum. But here 10 is used directly to calculate the sum, because if 0 and 10 are used to take the remainder, both are 0; if 1 is taken to take the remainder as 0, it can be reversed that the remainder should be 9.
  var checkNumber = 5;
  const isLegal =
    sum % checkNumber == 0 || ((sum + 1) % checkNumber == 0 && idArray[6] == 7);

  return isLegal;
};

export const vertifyTWId = (id) => {
  if (id == null || id == undefined) return false;

  id = id.trim();

  if (!id.match("^[A-Z][12]\\d{8}$")) {
    return false;
  }

  let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
  let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

  id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

  var checkSum = 0;
  for (var i = 0; i < id.length; i++) {
    var c = parseInt(id[i]);
    var w = weights[i];
    checkSum += c * w;
  }

  return checkSum % 10 == 0;
};

export const vertifyTWMobile = (telno) => {
  if (telno === null || telno === undefined) return false;

  if (!telno.match("^09\\d{8}$")) {
    return false;
  }
  return true;
};

export const getB64fromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(file);
  });
};

export const getB64fromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
