<template>
  <v-app>
    <!-- <update v-if="isShow" /> -->
    <router-view /> 
    <div class="bottom-info d-flex align-center justify-center" v-if="!isAccept">
      <v-container class="pa-5" style="max-width: 1440px;">
        <v-row no-gutters class="d-flex justify-space-around align-center">
          <v-col cols="12" lg="10" class="t-color-white pr-0 pr-lg-8">
            我們紀錄 cookie 資訊，以提供客製化內容及優化使用者體驗，若繼續閱覽本網站內容，即表示您同意我們使用 cookies。更多關於隱私保護資訊，請閱覽我們的 <span class="text-cursor font-weight-bold" style="border-bottom: 1px #FFF solid; padding-bottom: 0px;" @click="privacy()">隱私權保護政策</span>。
          </v-col>
          <v-col cols="12" lg="2" class="pt-2 pt-lg-0 pr-0 pr-lg-1">
            <v-btn class="font-weight-regular" rounded outlined block color="white" elevation="0"
              @click="setCookie()">我接受</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <privacyDialog />
  </v-app>
</template>

<script>
import moment from "moment-timezone";
import update from "@/views/update.vue";
import privacyDialog from "@/components/privacyDialog.vue";
export default {
  name: 'App',
  components: { update, privacyDialog },
  data: () => ({
    //
    isShow: false,
    isAccept: false,
  }),
  mounted() {
    const vm = this;
    //vm.setExpired();
    vm.checkCookie();
  },
  methods: {
    setExpired() {
      const vm = this;
      const tmpStartDate = moment("2023-12-27 00:00:00").format("x");
      const tmpExpireDate = moment("2023-12-27 07:00:00").format("x");
      let tmpNowDate = moment().format("x");
      if (
        parseInt(tmpExpireDate) > parseInt(tmpNowDate) &&
        parseInt(tmpNowDate) > parseInt(tmpStartDate)
      ) {
        vm.isShow = true;
      }
    },
    setCookie() {
      const vm = this;
      const expires = new Date();
      expires.setDate(expires.getDate() + 180);
      document.cookie = `cookie_policy=accepted; expires=${expires.toUTCString()}; path=/`;
      vm.isAccept = true;
    },
    checkCookie() {
      const vm = this;
      const cookies = document.cookie.split('; ')
      for (const cookie of cookies) {
        const [name, value] = cookie.split('=')
        if (name === 'cookie_policy' && value === 'accepted') {
          vm.isAccept = true
          break
        }
      }
    },
    privacy() {
      const vm = this;
      vm.$router.push({ path: "#privacy-dialog" });
    },
  }
};
</script>
