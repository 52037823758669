import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import "./styles/global.css";

//測試站或正式站
Vue.prototype.$env = process.env.NODE_ENV === "production" ? "prod" : "uat";

Vue.prototype.$log = (...args) => {
  //console.log("================================", process.env.NODE_ENV);
  if(process.env.NODE_ENV !== "production") {
    console.log(...args);
  }
}
// 正式站關閉chrome vue devTools
let isDebug_mode = process.env.NODE_ENV !== "production";
// isDebug_mode = true;
Vue.config.debug = isDebug_mode;
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = isDebug_mode;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
